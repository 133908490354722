@tailwind base;
@tailwind components;
@tailwind utilities;

* {
 @apply font-body
}

html, body, #root {
    @apply h-full bg-gray-50 text-slate-500;
}

h1, h2, h3, h4, h5, h6 {
    @apply mb-2 text-slate-700;
}

h5 {
    @apply text-5 leading-5.5;
}

.field-error {
    @apply !border-red-400 outline-none;
}

.dropzone {
    @apply flex-1 flex flex-col items-center p-5 border-2 rounded-1 border-[#eee] border-dashed bg-[#fafafa] text-[#bdbdbd] outline-none;
    transition: border .24s ease-in-out;
}